{
  "locale": "vi",
  "translations": {
    "ACAS-EMV-3DS": "ACAS-EMV 3DS",
    "ACASEMVInformation": " Thông Tin Dịch Vụ ACAS EMV ",
    "ACASEMVManagement": "Quản Lí Thông Tin Dịch Vụ ACAS EMV",
    "ACASEMVManagementDetail": "Thông Tin Chi Tiết Dịch Vụ ACAS EMV",
    "ACASEMVSearch": "Tra Cứu Dịch Vụ ACAS EMV",
    "ACASKey": "ACAS Key",
    "AccountClosed": "Tài khoản đã bị khóa",
    "AccountNumber": "Số tài khoản thanh toán",
    "AccountPan": "Số tài khoản thanh toán/ Số thẻ",
    "AcquirerCountry": "Nơi chấp nhận thanh toán",
    "AReq": "AReq",
    "ARes": "ARes",
    "Action": "Thao tác",
    "ActionDateTime": "Ngày thực hiện",
    "ActionName": "Thao tác",
    "ActionSend": "Gửi mail",
    "ActionTest": "Gửi mail (test)",
    "ActionType": "Loại thao tác",
    "ActionUser": "Tên người thực hiện",
    "ActionUserID": "Người thực hiện",
    "Activate": "Kích hoạt",
    "ActivateAt": "Thời gian kích hoạt",
    "Active": "Hoạt động",
    "Add": "Thêm mới",
    "AddGlobalScreenConfigurations": "Thêm mới màn hình Global",
    "AddingRulestoPolicy": "Thêm Rules vào Policy",
    "AddNew": "Thêm mới",
    "AddressLine1": "Địa chỉ dòng 01 ",
    "AddressLine2": "Địa chỉ dòng 02 ",
    "AddRule": "Thêm Rule",
    "Administration": "Quản trị hệ thống",
    "All": "Tất cả",
    "AllowConfigFrictionless": "Cấu hình Frictionless",
    "Amount": "Số tiền",
    "ApplePay": "ApplePay",
    "ApplePayNumberTokens24h": "Apple Pay - Số lượng Token trong 24h",
    "Apply": "Áp dụng",
    "AuthKeyGenerateMessage": "Integration Authentication Key được tạo giúp BU tích hợp với dịch vụ Push Provisioning",
    "Back": "Quay lại",
    "Before": "Trước đây",
    "BID": "BID",
    "BillingNumber": "Mã đơn hàng",
    "BIN": "BIN",
    "BINID": "BIN ID",
    "BinRange": "Bin-range",
    "BINRANGE": "BIN-Range",
    "BINRangeConfigured": "Cấu hình BIN-Range",
    "BU": "BU",
    "BuCode": "Mã BU",
    "BUCode": "Mã BU",
    "BUIntegration": "Tích Hợp BU",
    "BUName": "BU",
    "BuName": "Tên BU",
    "BusinessUnit": "BU",
    "BusinessUnitAdd": "Thêm mới BU",
    "BusinessUnitDetail": "Thông tin chi tiết BU",
    "BusinessUnitDisabled": " Vô hiệu BU",
    "BusinessUnitInfo": "Thông tin BU",
    "BusinessUnitManagement": "Quản lý BU",
    "BusinessUnitSearch": "Tìm kiếm BU",
    "BUStatus": "Trạng thái BU",
    "BUTestPage": "Trang Kiểm Thử BU",
    "Cache": "Cache",
    "CacheDetails": "Chi tiết cache",
    "CacheKey": "Khóa cache",
    "CacheManagement": "Quản lý cache",
    "CacheType": "Loại cache",
    "Cancel": "Hủy",
    "CardProgram": "Quản lý Thông Tin Thẻ",
    "CardProgramAdd": "Thêm mới Thông Tin Thẻ",
    "CardProgramDetail": "Thông tin chi tiết Thông Tin Thẻ",
    "CardProgramDuplicate": " Sao chép BIN Range.",
    "CardProgramExist": " Tồn tại BIN Range.",
    "CardProgramInvalidBU": "BU không tồn tại hoặc bị vô hiệu",
    "CardProgramInvalidService": "Dịch vụ không tồn tại hoặc bị vô hiệu.",
    "CardProgramManagement": "Quản lý Thông Tin Thẻ",
    "CardProgramMinBiggerMax": " Số Bắt đầu không được lớn hơn Số kết thúc.",
    "CardProgramSearch": "Tìm kiếm Thông tin Thẻ",
    "Category": "Danh mục",
    "CategoryManagement": "Cấu hình",
    "CAVVKey": "CAVV Key",
    "CC_Email": "CC Email",
    "Certificate": "Certificate",
    "Challenge": "Challenge",
    "ChangePassword": "Đổi mật khẩu",
    "CheckList": "Tiêu chí kiểm tra",
    "CheckReady": "Trạng thái thông tin của dịch vụ",
    "CloneRule": "Nhân bản Rule",
    "CloneRuleFrom": " Nhân bản Rule Từ ",
    "Comment": "Nhận xét",
    "Config": " Cấu hình ",
    "ConfigBinRange": "Cấu hình Bin-Range",
    "Confirm": "Xác nhận",
    "ConfirmConsumerInfoBeforeActivation": "Vui lòng xác nhận thông tin khách hàng trước khi kích hoạt Token",
    "ConfirmMastercardRequest": "Việc này sẽ mất ít thời gian để thực hiện yêu cầu từ Mastercard, bạn có muốn tiếp tục?",
    "ConfirmNewPassword": "Xác nhận mật khẩu",
    "ConfirmPasswordMismatch": "Mật khẩu xác nhận không trùng khớp",
    "ConfirmRenew": "Token sẽ được tạo mới theo chính sách của Mastercard, bạn có muốn tiếp tục?",
    "ConfirmUnsuspendToken": "Bạn có muốn tiếp tục khóa Token?",
    "ConsumerId": "Consumer Id",
    "ConsumerKey": "Consumer Key",
    "ConsumerKeyValidUntil" : "Consumer Key có hiệu lực đến",
    "CreatedAt": "Tạo tại ",
    "CreatedBy": "Tạo bởi",
    "CreatePolicyDraft": "Tạo bản nháp Policy",
    "Credit": "Ghi có",
    "CReq": "CReq",
    "CRes": "CRes",
    "Currency": "Tiền tệ",
    "Current": "Hiện tại",
    "CurrentValue": "Giá trị hiện tại",
    "Dashboard": "Dashboard",
    "Date": "Thời gian",
    "DateCreated": "Ngày tạo",
    "DateFormat": "Thời gian",
    "DateTime": "Thời gian",
    "DateTimeModify": "Ngày giờ cập nhật",
    "DateTimePublished": "Ngày giờ Xuất Bản",
    "Debit": "Ghi nợ",
    "Default": "Mặc định",
    "DefaultLanguage": "Ngôn ngữ mặc định",
    "Delete": "Xóa",
    "DeleteConfirm": "Xác Nhận Xóa",
    "Description ": "Mô tả ",
    "Description": "Mô tả",
    "Description[5]": "Mô tả[5]",
    "Detail": "Chi tiết",
    "DetailedNumberRegisteredTokens24h": "Số lượng chi tiết token đăng ký mới trong 24h",
    "DetailedNumberRegisteredTokens30h": "Số lượng chi tiết token đăng ký mới trong 30 ngày",
    "Details": "Chi tiết",
    "DeviceLost": "Thiết bị bị mất",
    "DeviceSerialNumber": "Số Sê-ri Thiết bị",
    "DeviceStolen": "Thiết bị bị đánh cắp",
    "DeviceType": "Loại thiết bị",
    "Disable": "Vô hiệu",
    "Disabled": "Vô hiệu",
    "DisplayName:Display Name": "Tên Hiển Thị",
    "DisplayName": "Tên hiển thị",
    "DisplayNeedHelpLabel": "Hiển thị nhãn Cần Sự Trợ Giúp",
    "DisplayTermOfUseLabel": "Hiển thị nhãn Điều khoản Sử dụng",
    "DuplicationRequest": "Dữ liệu đã tồn tại, vui lòng kiểm tra lại.",
    "Edit": "Chỉnh sửa",
    "Email": "Email",
    "Email1": "Email 1",
    "Email2": "Email 2",
    "EmptyTableMessage": "Không tìm thấy kết quả phù hợp",
    "Enable": "Đã Kích Hoạt",
    "EnableServiceACAS": "Đã Kích hoạt dịch vụ ACAS",
    "EncryptionCertificateFile": "Tệp Encryption Certificate",
    "EncryptionCertificateFingerprint": "Encryption Certificate Fingerprint",
    "EncryptionCertificateValidUntil" : "Encryption Certificate có hiệu lực đến",
    "End": "Kết thúc",
    "EnterNewPassword": "Nhập mật khẩu mới",
    "EnterOldPassword": "Nhập mật khẩu cũ",
    "Environment": "Môi trường",
    "ErrorMessage": "Thông báo lỗi",
    "ErrorMessage[21]": "Thông báo lỗi [21]",
    "ExistBINRangeEnable": "Tồn tại ít nhất 1 BIN Range dịch vụ ACAS ",
    "ExistsGlobalScreenServiceConfig": "Đã cấu hình cho màn hình Global  ",
    "ExistsPolicyPublish": "Tồn tại ít nhất 1 Policy đã Xuất Bản",
    "ExpireDate": "Thời gian hết hạn",
    "ExpiredPasswordTitle": "Mật khẩu đã hết hạn vào {$INTERPOLATION}, bạn vui lòng cập nhật mật khẩu để tiếp tục sử dụng cho ATG Digitals Portal.",
    "ExpirePasswordDateTime": "Thời gian mật khẩu hết hạn: {$INTERPOLATION}",
    "ExportCsv": "Xuất Csv",
    "ExportExcel": "Xuất excel",
    "ExportZip": "Xuất Zip",
    "Fail with Feedback": "Thất bại với phản hồi",
    "Fail": "Thất bại",
    "FirstName": "Tên đầu tiên",
    "ForgotPassword": "Quên mật khẩu",
    "ForgotPasswordMessage": " Chúng tôi đã gửi email đặt lại mật khẩu đến {$START_TAG_SPAN}{$INTERPOLATION}{$CLOSE_TAG_SPAN}. Vui lòng thực hiện theo hướng dẫn để hoàn tất quy trình cập nhật mật khẩu ",
    "ForgotPasswordNotification": " Nếu bạn quên mật khẩu, chúng tôi sẽ gửi một email cho bạn với liên kết đặt lại mật khẩu. Bạn vui lòng nhấn vào liên kết đó và làm theo hướng dẫn để tạo một mật khẩu mới ",
    "FromDate":"Từ ngày",
    "General": "Quản lý chung",
    "GeneralConfig": "Thông Tin Cấu Hình",
    "GeneralInformation": "Thông tin quản lý chung",
    "GenerateKey": "Tạo Key",
    "GlobalScreen": "Màn hình Global",
    "GlobalScreenCheckConfigurations": "Kiểm Tra Cấu Hình Màn Hình Global",
    "GlobalScreenConfigurations": "Cấu hình màn hình Global",
    "GlobalScreenConfigurationsDetail": "Màn hình chi tiết Gobal",
    "GlobalScreenConfigurationsSearch": "Tra cứu màn hình Global",
    "GlobalScreenLogDetail": "Lịch sử hoạt động màn hình Global",
    "GooglePay": "GooglePay",
    "GooglePayNumberTokens24h": "Google Pay - Số lượng Token trong 24h",
    "IncorrectCode": "Mã không chính xác",
    "IncorrectCode[15]": "Mã không chính xác [15]",
    "IncorrectCode[15]/LeftBlankCode[16]": "Mã không chính xác[15]/Còn lại số lần thử[16]",
    "Infomation": "Thông tin",
    "InfoMethod": "Thông tin/ Phương thức",
    "InternalServerError": "Lỗi hệ thống",
    "InvalidNewPassword": "Mật khẩu mới không được trùng với 5 mật khẩu gần nhất, vui lòng kiểm tra lại",
    "InvalidOldPassword": "Mật khẩu cũ không hợp lệ",
    "InvalidPhoneNumber": "Số điện thoại không hợp lệ",
    "InvalidURL": "URL không hợp lệ",
    "IPAddress": "Địa chỉ IP",
    "IsReady": "Dịch Vụ Sẵn Sàng",
    "ItemName": "Thông tin",
    "LabelNeedHelp": "Nhãn Cần Sự Trợ Giúp ",
    "LabelNeedHelp[8]": "Nhãn Cần Sự Trợ Giúp [8]",
    "LabelSubmit": "Nhãn Gửi",
    "LabelSubmit[14]": "Nhãn Gửi [14]",
    "LabelSubmit[6]": "Nhãn Gửi [6]",
    "LabelTermOfUse": "Nhãn Điều Khoản Sử Dụng",
    "LabelTermOfUse[9]": "Nhãn Điều Khoản Sử Dụng [9]",
    "Language": "Ngôn ngữ",
    "LanguageType": "Loại ngôn ngữ",
    "LastEditPasswordDateTime": "Thời gian đổi mật khẩu gần nhất: {$INTERPOLATION}",
    "LastimeModified": "Thời gian chỉnh sửa gần nhất",
    "LastLoginDate:Last Login Date": "Ngày đăng nhập gần đây",
    "LastLoginDate": "Truy cập gần đây",
    "LastName": "Tên",
    "LastStatusUpdatedAt": "Trạng thái gần nhất",
    "LatestDateTimeSent": "Lần gửi gần nhất",
    "LatestEmailReceived": "Người nhận gần nhất",
    "LatestUserSent": "Người gửi gần nhất",
    "LeftBlankCode": "Còn lại số lần thử",
    "LeftBlankCode[16]": "Còn lại số lần thử[16]",
    "ListOfReports": "Danh sách Báo Cáo",
    "ListRule": "Danh sách Rules",
    "ListRuleAddEdit": "Danh sách Rules (Tạo hoặc chỉnh sửa)",
    "Locked": "Khóa",
    "LogAction": "Lịch sử thao tác",
    "LogActionHistory": "Lịch sử thao tác",
    "LogDetail": "Chi tiết Log",
    "LogDetailChanges": "Chi tiết Log(thay đổi)",
    "LogDetailInformation": "Thông tin chi tiết Log",
    "LogInformation": "Thông tin lịch sử",
    "MasterCardTokenDetail": "Thông tin chi tiết Token Mastercard",
    "MaxVerifyOTP": "Số lần cho phép tối đa thử lại OTP",
    "MCToken": "MC Token",
    "Message.AccessDenied": "Bạn không có quyền truy cập.",
    "Message.ActivateAccountConfirm": "Bạn có chắc chắn muốn kích hoạt tài khoản??",
    "Message.BINNoExists": "BIN không tồn tại",
    "Message.BinRangeDisabled": "Có một BIN Range bị vô hiệu, vui lòng thử lại",
    "Message.BUCodeExist": "BU Code đã tồn tại!",
    "Message.BUDeclaredService": "BU đã được khai báo dịch vụ",
    "Message.BUDisabled": "BU vừa bị vô hiệu, vui lòng thử lại.",
    "Message.ChangePasswordSuccess": "Cập nhật mật khẩu mới thành công",
    "Message.ChangesYouMadeMayNotBeSaved": "Thay đổi của bạn sẽ không được lưu.",
    "Message.ChanllengeCancelConfirm": "Bạn có muốn hủy thanh toán?",
    "Message.CloneFail": "Nhân Bản thất bại. ",
    "Message.CloneSuccess": "Nhân Bản thành công",
    "Message.ComebackLater": "Vui lòng thử lại sau",
    "Message.ConfirmGenerateKey": "Bạn có muốn tạo key mới?",
    "Message.ConsumerKey": "Consumer Key",
    "Message.ConsumerKeyValidUntil": "Thời gian Consumer Key hợp lệ phải lớn hơn thời gian hiện tại.",
    "Message.ContactAdminstrator": "Vui lòng thử lại hoặc liên hệ người quản trị hệ thống",
    "Message.CreatePolicyDraftSuccess": "Tạo bản nháp Policy thành công",
    "Message.DataNotFound": "Không tìm thấy dữ liệu liên quan.",
    "Message.DisableAccountConfirm": "Bạn có chắc muốn vô hiệu tài khoản??",
    "Message.Don'tCancel": "Không được Huỷ",
    "Message.EmailDuplicate": "Email1 và Email2 không được giống nhau.",
    "Message.EncryptionCertificate Key": "Encryption Certificate Key",
    "Message.EncryptionCertificateValidUntil": "Thời gian Encryption Certificate hợp lệ phải lớn hơn thời gian thiện tại.",
    "Message.Error403": "Bạn không có quyền truy cập thông tin này",
    "Message.Failed": "Thất bại",
    "Message.FailSendMail": "Gửi mail thất bại",
    "Message.FailureAction": "Cập nhật thất bại. ",
    "Message.GenerateKey": "Tạo Key",
    "Message.GenerateKeySuccess": "Tạo key thành công",
    "Message.InsertFail": "Thêm mới thất bại.",
    "Message.InsertSuccess": "Thêm mới thành công",
    "Message.InsertSuccessfully": "Thêm mới thành công",
    "Message.IntegrationAuthenticationKey": "Integration Authentication Key",
    "Message.InternalServerError": "Lỗi sever nội bộ.",
    "Message.Invalid": " {$PH} không hợp lệ : {$PH_1}",
    "Message.InvalidAction": "Thao tác không hợp lệ.",
    "Message.InvalidCardBINLength": "Đầu BIN gồm 6 hoặc 8 kí tự",
    "Message.InvalidDate": "Ngày không hợp lệ",
    "Message.InvalidEmail": "Email không hợp lệ.",
    "Message.InvalidEmailCC": "Bảo đảm email được nhập đúng tiêu chuẩn(e.g., example@domain.com)",
    "Message.InvalidExpireDate": "Ngày hết hạn lớn hơn hoặc bằng ngày hiện tại.",
    "Message.InvalidFormatYYMM": "Định dạng: YYMM",
    "Message.InvalidMax": "Giá trị tối đa là {$INTERPOLATION}",
    "Message.InvalidMaxLength": "Tối đa {$INTERPOLATION} ký tự",
    "Message.InvalidMin": "Giá trị tối thiểu là {$INTERPOLATION}",
    "Message.InvalidMinLength": "Phải có ít nhất {$INTERPOLATION} ký tự",
    "Message.InvalidMinMaxBINLength": "Độ dài chưa hợp lệ",
    "Message.InvalidMonth": "Tháng không hợp lệ",
    "Message.InvalidPhoneNumber": "Phải nhập đủ 10 ký tự số và ký tự bắt đầu tiên bắt đầu số 0",
    "Message.InvalidRequest": "Yêu cầu không hợp lệ",
    "Message.InvalidSession": "Phiên đăng nhập đã kết thúc. Vui lòng đăng nhập lại.",
    "Message.InvalidToken": "Token không hợp lệ",
    "Message.KeysInformation": "BU Key",
    "Message.LeaveSite": "Rời trang?",
    "Message.MaxDate": "Ngày được chọn phải nhỏ hơn",
    "Message.MIDNotExist": "MID không tồn tại",
    "Message.MinDate": "Ngày được chọn phải lớn hơn",
    "Message.NewPasswordMatchOldPassword": "Mật khẩu mới không được trùng với mật khẩu cũ, vui lòng kiểm tra lại.",
    "Message.No": "Không",
    "Message.NotFoundText": "Không tìm thấy thông tin",
    "Message.NoWhitespace": "Không được có khoảng cách trắng",
    "Message.OverSizeFileUpload": "Tệp vừa tải lên vượt qua kích cỡ cho phép. Vui lòng kiểm tra lại.",
    "Message.PasswordPolicyNotMatch": "Mật khẩu mới chưa đúng định dạng quy định, vui lòng nhập lại.",
    "Message.PhoneNumberExist": "Số điện thoại đã tồn tại!",
    "Message.PleaseSelectBU": "Vui lòng chọn BU trước khi thêm Rule",
    "Message.PleaseSelectFileEncryptionCertificateFile": "Vui lòng chọn Encryption Certificate File.",
    "Message.PleaseSelectFileSigningKeyFile": "Vui lòng chọn tệp Signing Key .",
    "Message.PublicKeyRequired": "Public key không được bỏ trống.",
    "Message.PublishPolicyDraftSuccess": "Xuất Bản Bản Nháp Policy thành công",
    "Message.PublishPolicySuccess": "Xuất Bản Policy thành công",
    "Message.ReportCriteriaInformation": "Tiêu chí báo cáo",
    "Message.RequestChangePasswordSuccess": "Hướng dẫn đặt lại mật khẩu đã được gửi tới email của bạn.",
    "Message.required": "Trường bắt buộc nhập",
    "Message.RequiredFail": "Vui lòng nhập các trường bắt buộc",
    "Message.RoleInformation": "Thông tin phân quyền",
    "Message.SelectAtLeastOneAuthenticationMethod": "Chọn ít nhất 1 phương thức xác thực",
    "Message.SigningKey": "Signing Key",
    "Message.SigningKeyValidUntil": "Thời gian The Signing Key hợp lệ khi lớn hơn thời gian hiện tại.",
    "Message.SuccessAction": "Thao tác thành công.",
    "Message.SuccessSendMail": "Gửi mail thành công",
    "Message.TabCardProgram": "Thông tin Thẻ",
    "Message.TheBUHasBeenConfiguredWithAllLanguageTypes": "\n         BU đã được cấu hình đầy đủ các loại ngôn ngữ, vui lòng kiểm tra lại.",
    "Message.TokenExpiration": "Token đã hết hạn",
    "Message.UnderMaintenance": "Hệ thống đang bảo trì",
    "Message.UpdateFail": "Cập nhật thất bại. ",
    "Message.UpdatePasswordSuccess": "Cập nhật thành công với mật khẩu mới",
    "Message.UpdateSuccess": "Cập nhật thành công",
    "Message.UpdateSuccessfully": "Cập nhật thành công",
    "Message.ValidConfiguration": "Cấu hình hợp lệ",
    "Message.Yes,Cancel": "Có, hủy bỏ",
    "Message.Yes": "Có",
    "MessageBody": "Message Body",
    "MessageHeader": "Message Header",
    "Methods": "Phương thức xác thực",
    "ModifyDateTime": "Thời gian chỉnh sửa",
    "Month": "Tháng",
    "Name": "Tên",
    "NeedHelp": "Cần Sự Trợ Giúp?",
    "NewPassword": "Mật khẩu mới",
    "NewValue": "Giá trị hiện tại",
    "Next": "Tiếp tục",
    "ngb.alert.close": "Đóng",
    "ngb.carousel.next": "Tiếp theo",
    "ngb.carousel.previous": "Trước",
    "ngb.carousel.slide-number": " Trang {$INTERPOLATION} thuộc {$INTERPOLATION_1} ",
    "ngb.datepicker.next-month": "Tháng tiếp theo",
    "ngb.datepicker.previous-month": "Tháng trước",
    "ngb.datepicker.select-month": "Chọn tháng",
    "ngb.datepicker.select-year": "Chọn năm",
    "ngb.pagination.first-aria": "Đầu tiên",
    "ngb.pagination.first": "««",
    "ngb.pagination.last-aria": "Cuối cùng",
    "ngb.pagination.last": "»»",
    "ngb.pagination.next-aria": "Tiếp theo",
    "ngb.pagination.next": "»",
    "ngb.pagination.previous-aria": "Trước",
    "ngb.pagination.previous": "«",
    "ngb.progressbar.value": "{$INTERPOLATION}",
    "ngb.timepicker.decrement-hours": "Giảm giờ",
    "ngb.timepicker.decrement-minutes": "Giảm phút",
    "ngb.timepicker.decrement-seconds": "Giảm giây",
    "ngb.timepicker.HH": "HH",
    "ngb.timepicker.hours": "giờ",
    "ngb.timepicker.increment-hours": "Tăng giờ",
    "ngb.timepicker.increment-minutes": "Tăng phút",
    "ngb.timepicker.increment-seconds": "Tăng giây",
    "ngb.timepicker.minutes": "phút",
    "ngb.timepicker.MM": "MM",
    "ngb.timepicker.PM": "{$INTERPOLATION}",
    "ngb.timepicker.seconds": "giây",
    "ngb.timepicker.SS": "SS",
    "ngb.toast.close-aria": "Đóng",
    "NotApplicable": "Không áp dụng",
    "Notice": "Lưu ý: ",
    "NumberTokens30days": "'Số lượng Token trong 30 ngày",
    "OK": "Đồng ý",
    "OldPassword": "Mật khẩu cũ",
    "OldValue": "Giá trị cũ",
    "OOB": "OOB",
    "OrderNumber": "STT",
    "OSName": "OSName",
    "Other": "Khác",
    "OtherLanguage": "Ngôn ngữ khác",
    "OTPEmail": "OTP Email",
    "OTPEMail": "OTP Email",
    "OTPEOTT": "OTP OTT",
    "OTPOOB": "OTP OOB",
    "OTPOTT": "OTP OTT",
    "OTPSMS": "OTP SMS",
    "Outcome": "Kết quả",
    "Page": "Trang",
    "PANLength": "Độ dài PAN",
    "Password": "Mật khẩu",
    "PasswordChangeNotice": "Mật khẩu gồm 8-64 kí tự, trong đó bao gồm ít nhất 1 số, 1 chữ, 1 kí tự đặc biệt và không được trùng với 5 mật khẩu gần nhất ",
    "PayByAccount": "Pay By Account",
    "PendingChange": "Thay đổi đang chờ xử lý",
    "PERCENT": "PHẦN TRĂM %",
    "PermissionLog": "Lịch Sử Cập Nhật Quyền",
    "PhoneNumber": "Số điện thoại",
    "PleaseSelectCacheType": "Vui lòng chọn loại cache",
    "Policy": "Policy",
    "PolicyAdd": "Thêm mới Policy",
    "PolicyCurrent": "Policy hiện tại",
    "PolicyDetail": "Chi tiết Policy",
    "PolicyEditor": "Chỉnh sửa Policy",
    "PolicyID": "Policy ID",
    "PolicyInformation": "Thông tin Policy",
    "PolicyLogAction": "Lịch sử thao tác",
    "PolicyLogDetail": "Lịch sử thao tác chi tiết",
    "PolicyManagement": "Quản lý thông tin Policy",
    "PolicyName": "Tên Policy",
    "PolicyPublishedAndBINRangeConfiguration": "Xuất Bản Policy & Cấu hình BIN-Range",
    "PolicySearch": "Tìm kiếm Policy",
    "PolicyUnsaveSelectedRule": "Bạn có chắc muốn rời khỏi? Các Rule bạn thay đổi sẽ không được lưu.",
    "Previous": "Trước",
    "Production": "Production",
    "Profile": "Thông tin hồ sơ",
    "PublicKey": "Public Key",
    "PublishAndConfig": "Xuất Bản và Cấu hình",
    "PublishDateTime": "Thời gian Xuất Bản",
    "PublishPolicyConfirmination": "Bạn có chắc muốn lưu thay đổi?",
    "PublishPolicyDraft": "Xuất Bản Policy Draft",
    "PublishPolicyOverrideConfirmation": "Dữ liệu đã được thay đổi, thao tác này sẽ xóa dữ liệu cũ. Bạn vẫn muốn tiếp tục?",
    "PushProvisioning": "Push Provisioning",
    "PushProvisioningAdd": " Thêm mới dịch vụ Push Provisioning",
    "PushProvisioningDashboard": "Push Provisioning Dashboard",
    "PushProvisioningDetail": "Chi tiết dịch vụ Push Provisioning",
    "PushProvisioningInformation": "Thông tin dịch vụ Push Provisioning",
    "PushProvisioningManagement": "Quản lý Push Provisioning",
    "PushProvisioningRegister": "Đăng ký Push Provisioning",
    "PushProvisioningSearch": "Tìm kiếm Push Provisioning",
    "PwdRuleChar": "Tối thiểu 1 kí tự chữ",
    "PwdRuleLength8And64": "Từ 8 đến 64 ký tự",
    "PwdRuleNum": " Tối thiểu 1 kí tự số",
    "PwdRuleSpec": "Tối thiểu 1 ký tự đặc biệt. Ví dụ: !@#$%^*()”",
    "Quantity": "Số lượng",
    "ReasonCode": "Mã lỗi",
    "Rejected": "Từ chối ",
    "ReloadAll": "Tải lại tất cả",
    "ReloadCache": "Tải lại cache",
    "Remark:Remark": "Đánh dấu",
    "Remark": "Ghi chú",
    "Remove": "Xóa",
    "RemoveCache": "Xóa cache",
    "RemovePolicyDraftConfirmination": "Bạn có muốn tiếp tục? Thao tác này sẽ xóa bản Draft hiện có của Policy. Mọi thay đổi chưa lưu sẽ bị mất",
    "Report": "Báo cáo",
    "ReportCriteria": "Tiêu chí báo cáo",
    "ReportGroup": "Nhóm báo cáo",
    "RequestChangePassword": "Gửi yêu cầu đổi mật khẩu",
    "ReturnHomePage": "Quay lại trang chủ",
    "ReturnToLoginPage": "Quay lại trang chủ",
    "ReviewRule": "Kiểm tra Rule",
    "Role": " Quyền ",
    "RoleName": "Tên Quyền",
    "RowPerPage": "Số dòng",
    "RReq": "RReq",
    "RRes": "RRes",
    "Rule": "Rule",
    "RuleDescription": "Mô tả Rule",
    "RuleDetail": "Chi tiết Rule",
    "RuleID": "Rule ID",
    "RuleLog": "Thao tác",
    "RuleLogAction": "Lịch sử thao tác",
    "RuleManagement": "Quản Lý Rule",
    "RuleName": "Tên Rule",
    "RuleSearch": "Tra cứu Rule",
    "RulesEditor": "Chỉnh sửa Rules",
    "RulesManagement": "Quản lý Rules",
    "RulesSearch": "Tra cứu Rules",
    "RuleStatement": "Điều kiện Rule",
    "Save": "Lưu",
    "Search": "Tìm kiếm",
    "SendMail": "Gửi mail",
    "SendMailConfirmation": "Bạn có chắc gửi mail?",
    "SendPushProvisioningReport": "Báo cáo gửi Push Provisioning",
    "SendRequest": "Gửi yêu cầu",
    "Service": "Dịch vụ",
    "ServiceInformation": "Thông tin dịch vụ Service",
    "ServiceName": "Tên dịch vụ",
    "SetPasswordTitle": "Đặt lại mật khẩu",
    "SignIn": "Đăng nhập",
    "SigningKeyAlias": "Signing Key Alias",
    "SigningKeyFile": "Tệp Signing Key",
    "SigningKeyPassword": "Mật khẩu Signing Key",
    "SigningKeyValidUntil" : "Signing Key có hiệu lực đến",
    "SignInTitle": "ĐĂNG NHẬP",
    "SignOut": "Đăng Xuất",
    "Start": "Bắt đầu",
    "Status: Status": "Trạng thái",
    "Status:Status": "Trạng thái",
    "Status": "Trạng thái",
    "StatusPublish": "Trạng thái Publish",
    "StepUpMethods": "Phương thức xác thực(s):",
    "StepUpMethodsConfig": "Cấu hình phương thức xác thực",
    "Submit": "Gửi",
    "Success": "Thành công",
    "SuspectedFraud": "Phát Hiện Gian Lận",
    "System": "Hệ Thống",
    "Tab.AdditionalFields": "Fields Bổ Sung",
    "Tab.BillingInfomation": "Thông tin Hóa Đơn",
    "Tab.BusinessUnitInfomation": "Thông tin BU",
    "Tab.ErrorMessageInformation": "Thông tin mã lỗi",
    "Tab.FailWithFeedbackInformation": "Thông tin Fail With Feedback",
    "Tab.LogDetailInfomation": "Thông tin Thao tác chi tiết",
    "Tab.RuleInfomation": "Thông tin Rule",
    "Tab.RuleStatememt": "Điều kiện Rule",
    "Tab.StepUpInformation": " Thông tin StepUp",
    "Tab.SystemInformation": "Thông tin Hệ Thống",
    "Tab.TokenInfomation": "Thông tin chi tiết Token",
    "TemplateEmail": "Email đối soát",
    "TermOfUse": "Điều Khoản Sử Dụng",
    "TestConfiguration": "Kiểm thử cấu hình",
    "TestMail": "Kiểm thử mail",
    "ThreeDSRequestorChallengeInd": "ThreeDSRequestorChallengeInd",
    "TimeBlockPAN": "Thời gian khóa PAN",
    "TimeConfigFrictionless": "Thời gian Frictionless",
    "Title": "Tiêu đề",
    "Title[11]": "Tiêu đề [11]",
    "Title[17]": "Tiêu đề [17]",
    "Title[4]": "Tiêu đề [4]",
    "To_Email": "Đến Email",
    "ToDate" : "Đến ngày",
    "TokenHistory": "Lịch Sử Token",
    "TokenManagement": "Quản Lý Token",
    "TokenManagementDetail": "Chi Tiết Token",
    "TokenManagementSearch": "Tra Cứu Token",
    "TokenType": "Loại Token",
    "Total": "Tổng cộng",
    "TotalCaches": "Tổng cache",
    "TransactionDetail[12]": "Giao dịch chi tiết [12]",
    "TransactionDetail[18]": "Giao dịch chi tiết [18]",
    "TransactionID": "Transaction ID",
    "TransactionResult": "Kết Quả Giao dịch",
    "TransactionStatus": "Trạng thái Giao dịch",
    "TUR": "TUR",
    "UAT": "UAT",
    "Up/Down": "Lên/Xuống",
    "Update": "Cập nhật",
    "UpdateAccountConfirm": "Bạn có chắc muốn cập nhật thông tin tài khoản??",
    "Updated User": "Người dùng chỉnh sửa",
    "UpdatedAt": " Thời gian chỉnh sửa",
    "UpdatedBy": "Chỉnh sửa bởi",
    "UpdatedUser": "Người dùng chỉnh sửa",
    "URL": "URL",
    "UrlLogo": "Url Logo ",
    "URLlogo": "URL logo",
    "URLNeedHelp": "URL Cần Sự Trợ Giúp",
    "URLTermOfUse": "URL Điều Khoản Sử Dụng",
    "User Modify": "Người dùng chỉnh sửa",
    "User": "Người dùng",
    "UserAdd": "Thêm mới người dùng",
    "UserID": "Số ID người sử dụng",
    "UserIDModify": "Người cập nhật",
    "UserInformation": "Thông Tin Người Dùng",
    "UserManagement": "Quản lý người dùng",
    "UserModify": "Người cập nhật",
    "UserName": "Tên đăng nhập",
    "UsernameUpdate": "Điều chỉnh người dùng",
    "UserPublish": "Người dùng Xuất Bản",
    "UserPublished": "Người dùng được Xuất Bản",
    "UserSearch": "Tra cứu người dùng",
    "ValidateLabel": "Nhãn Kiểm Thử",
    "ValidateLabel[19]": "Nhãn Kiểm Thử [19]",
    "ValidateLink": "Link Kiểm thử",
    "ValidateLink[20]": "Link Kiểm thử [20]",
    "ValidationScreenOTPEmail": "Màn hình Kiểm thử OTP Email",
    "ValidationScreenOTPOOB": "Màn hình Kiểm thử OTP OOB",
    "ValidationScreenOTPOTT": "Màn hình Kiểm thử OTP OTT",
    "ValidationScreenOTPSMS": "Màn hình Kiểm thử OTP SMS",
    "Value": "Giá trị",
    "VerificationCode": "Mã xác thực",
    "VerificationCode[13]": "Mã xác thực [13]",
    "View": "Xem",
    "ViewDetailMasterCard": " Xem chi thiết thẻ Master Card ",
    "ViewPolicyCurrent": "Xem Policy gốc",
    "ViewPolicyDraft": "Xem bản Draft",
    "VisibleAdminOnly": "Chỉ hiển thị với người dùng quản trị",
    "ACAS-TransactionInquiry": "ACAS - Tra cứu giao dịch",
    "TransactionInquiry": "Tra cứu giao dịch",
    "Last4digit": "4 số cuối",
    "TransactionFrom": "Thời gian từ",
    "TransactionTo": "Thời gian kết thúc",
    "TransactionDateTime": "Thời gian thực hiện giao dịch",
    "CardNumberMasked": "Số thẻ Masked",
    "BUname": "Tên BU",
    "AuthenticationType": "Loại xác thực",
    "CurrencyCode": "Mã tiền tệ",
    "PurchaseAmt": "Số tiền giao dịch",
    "AuthenticationStatus": "Trạng thái xác thực",
    "DeviceChannel": "Kênh thiết bị",
    "MessageCategory": "Danh mục tin nhắn",
    "AccountType": "Loại tài khoản",
    "AcquirerBIN": "BIN Thanh Toán",
    "MerchantCategoryCode": "Danh mục cửa hàng",
    "MerchantName": "Tên cửa hàng",
    "MerchantCountry": "Nơi giao dịch",
    "Unverified": "Chưa xác thực",
    "AuthenticationRequired": "Yêu cầu xác thực",
    "ZZ": "EndOfTranslations"
  }
}
