import { inject } from '@angular/core';
import { CanMatchFn, Route, Router, UrlSegment } from '@angular/router';
import { Logger } from '@core/log';
import { ResponseCode } from '@core/response-code.type';
import { StorageKey, StorageWrapper } from '@core/storage';
import { catchError, map, of } from 'rxjs';
import { Role, RoleCode } from '../shared/types/role.type';
import { AuthService } from './auth.service';

export const Unauthenticated = (route: Route, segments: UrlSegment[]) => {
    const router = inject(Router);
    if (!StorageWrapper.get(StorageKey.ACCESS_TOKEN)) return true;
    const authService = inject(AuthService);
    return authService.getProfile(true).pipe(
      catchError(err => of(null)),
      map(response => {
        if (response?.Data) {
          Logger.log('User is authenticated, redirect to index page');
          return router.createUrlTree(['/']);
        }
        return true;
      })
    );
  }

export const Authorize = (role?: Role): CanMatchFn =>
  (route: Route, segments: UrlSegment[]) => {
    const router = inject(Router);
    if (!StorageWrapper.get(StorageKey.ACCESS_TOKEN)) return router.createUrlTree(['/auth/login']);

    const authService = inject(AuthService);
    return authService.getProfile().pipe(
      catchError(err => of(null)),
      map(response => {
        if (response?.ResponseCode !== ResponseCode.Success) {
          return router.createUrlTree(['/auth/login']);
        }
        const canMatch = role === undefined || response?.Data?.Roles?.includes(Role[role] as RoleCode);
        if (!canMatch) {
          Logger.log('Role not match', role, response?.Data?.Roles);
          return router.createUrlTree(['/error/forbidden']);
        }
        return true;
      })
    );
  }
