{
  "locale": "en-US",
  "translations": {
    "ACAS-EMV-3DS": "ACAS-EMV 3DS",
    "ACASEMVInformation": "ACAS EMV Information",
    "ACASEMVManagement": "ACAS EMV Management",
    "ACASEMVManagementDetail": "ACAS EMV Management Detail",
    "ACASEMVSearch": "ACAS EMV Search",
    "ACASKey": "ACAS Key",
    "AccountClosed": "Account closed",
    "AccountNumber": "Account Number",
    "AccountPan": "Account Number/Pan",
    "AcquirerCountry": "Acquirer Country",
    "Action": "Action",
    "ActionDateTime": "Action Date Time",
    "ActionName": "Action Name",
    "ActionSend": " ActionSend ",
    "ActionTest": " ActionTest ",
    "ActionType": "Action Type",
    "ActionUser": "Action User",
    "ActionUserID": "Action User",
    "Activate": "Activate",
    "ActivateAt": "Activate At",
    "Active": "Active",
    "Add": "Add",
    "AddGlobalScreenConfigurations": "Add Global Screen Configurations",
    "AddingRulestoPolicy": "Adding Rules to Policy Configuration",
    "AddNew": "Add New",
    "AddressLine1": "Address Line 1",
    "AddressLine2": "Address Line 2",
    "AddRule": "Add Rule",
    "Administration": "Administration",
    "All": "All",
    "AllowConfigFrictionless": "AllowConfigFrictionless",
    "Amount": "Amount",
    "ApplePay": "ApplePay",
    "ApplePayNumberTokens24h": "Apple Pay - Number of Tokens in 24h",
    "Apply": "Apply",
    "AReq": "AReq",
    "ARes": "ARes",
    "AuthKeyGenerateMessage": "Integration Authentication Key is generate for BU to integrate Push Provisioning",
    "Back": "Back",
    "Before": "Before",
    "BID": "BID",
    "BillingNumber": "Billing Number",
    "BIN": "BIN",
    "BINID": "BIN ID",
    "BinRange": "Bin-range",
    "BINRANGE": "BIN-Range",
    "BINRangeConfigured": "BIN-Range Configured",
    "BU": "BU",
    "BUCode": "BU Code",
    "BuCode": "BuCode",
    "BUIntegration": "BU Integration",
    "BUName": "BU Name",
    "BuName": "BuName",
    "BusinessUnit": "Business Unit",
    "BusinessUnitAdd": "Add Business Unit",
    "BusinessUnitDetail": "Business Unit Detail",
    "BusinessUnitDisabled": " Business Unit Disabled.",
    "BusinessUnitInfo": "Business Unit Info",
    "BusinessUnitManagement": "Business Unit Management",
    "BusinessUnitSearch": "Business Unit Search",
    "BUStatus": "BU Status",
    "BUTestPage": "Business Unit Test Page",
    "Cache": "Cache",
    "CacheDetails": "Cache Details",
    "CacheKey": "Key Cache",
    "CacheManagement": "Cache Management",
    "CacheType": "Cache Type",
    "Cancel": "Cancel",
    "CardProgram": "Card Program",
    "CardProgramAdd": "Card Program Add",
    "CardProgramDetail": "Card Program Detail",
    "CardProgramDuplicate": " Duplicate BIN Range.",
    "CardProgramExist": " Existed BIN Range.",
    "CardProgramInvalidBU": " BU does not exist or is disabled.",
    "CardProgramInvalidService": " Service does not exist or is disabled.",
    "CardProgramManagement": "Card Program Management",
    "CardProgramMinBiggerMax": " Start is not bigger End.",
    "CardProgramSearch": "Card Program Search",
    "Category": "Category",
    "CategoryManagement": "Category Management",
    "CAVVKey": "CAVVKey",
    "CC_Email": "CC Email",
    "Certificate": "Certificate",
    "Challenge": "Challenge",
    "ChangePassword": "Change Password",
    "CheckList": "Check List",
    "CheckReady": "Check Ready",
    "CloneRule": "Clone Rule",
    "CloneRuleFrom": " Clone Rule From ",
    "Comment": "Comment",
    "Config": " Config ",
    "ConfigBinRange": "Config Bin-range",
    "Confirm": "Confirm",
    "ConfirmConsumerInfoBeforeActivation": "Please confirm the consumer information before activating token",
    "ConfirmMastercardRequest": "It may take a couple of time to request info from Mastercard, are you sure to continue ?",
    "ConfirmNewPassword": "Confirm New Password",
    "ConfirmPasswordMismatch": "Confirmation password does not match",
    "ConfirmRenew": "Token will be renewed following the Mastercard regulation, Are you sure to continue ?",
    "ConfirmUnsuspendToken": "Are you sure to unsuspend the token ?",
    "ConsumerId": "Consumer Id",
    "ConsumerKey": "Consumer Key",
    "ConsumerKeyValidUntil" : "Consumer Key Valid Until",
    "CreatedAt": "Created At",
    "CreatedBy": "Created By",
    "CreatePolicyDraft": "Create Policy Draft",
    "Credit": "Credit",
    "CReq": "CReq",
    "CRes": "CRes",
    "Currency": "Currency",
    "Current": "Current",
    "CurrentValue": "Current Value",
    "Dashboard": "Dashboard",
    "Date": "Date",
    "DateCreated": "DateCreated",
    "DateFormat": "Date Format",
    "DateTime": "Date and Time",
    "DateTimeModify": "Date Time Modify",
    "DateTimePublished": "DateTimePublished",
    "Debit": "Debit",
    "Default": "Default",
    "DefaultLanguage": "Default Language",
    "Delete": "Delete",
    "DeleteConfirm": "",
    "Description ": "Description ",
    "Description": "Description",
    "Description[5]": "Description [5]",
    "Detail": "Detail",
    "DetailedNumberRegisteredTokens24h": "Detailed Number of Registered Tokens in 24h",
    "DetailedNumberRegisteredTokens30h": "Detailed Number of Registered Tokens in 30 days",
    "Details": "Details",
    "DeviceLost": "Device Lost",
    "DeviceSerialNumber": "Device Serial Number",
    "DeviceStolen": "Device Stolen",
    "DeviceType": "DeviceType",
    "Disable": "Disable",
    "Disabled": "Disabled",
    "DisplayName:Display Name": "DisplayName",
    "DisplayName": "Display Name",
    "DisplayNeedHelpLabel": "Display Need help Label",
    "DisplayTermOfUseLabel": "Display Term of use Label",
    "DuplicationRequest": " Data already exists, please check again.",
    "Edit": "Edit",
    "Email": "Email",
    "Email1": "Email 1",
    "Email2": "Email 2",
    "EmptyTableMessage": "No data found",
    "Enable": "Enable",
    "EnableServiceACAS": "Enable Service ACAS",
    "EncryptionCertificateFile": "Encryption Certificate File",
    "EncryptionCertificateFingerprint": "Encryption Certificate Fingerprint",
    "EncryptionCertificateValidUntil" : "Encryption Certificate Valid Until",
    "End": "End",
    "EnterNewPassword": "Enter New Password",
    "EnterOldPassword": "Enter Old Password",
    "Environment": "Environment",
    "ErrorMessage": "Error Message",
    "ErrorMessage[21]": "Error Message [21]",
    "ExistBINRangeEnable": "Exist BIN Range Enable",
    "ExistsGlobalScreenServiceConfig": "Exists Global Screen Service Config",
    "ExistsPolicyPublish": "Exists Policy Publish",
    "ExpireDate": "Expire Date",
    "ExpiredPasswordTitle": "Your password is expired on {$INTERPOLATION}, please update your password to continue using ATG Digitals Portal.",
    "ExpirePasswordDateTime": "Password expiration date and time: {$INTERPOLATION}",
    "ExportCsv": "Export Csv",
    "ExportExcel": "Export Excel",
    "ExportZip": "Export Zip",
    "Fail with Feedback": "Fail with Feedback",
    "Fail": "Fail",
    "FirstName": "First Name",
    "ForgotPassword": "Forgot Password",
    "ForgotPasswordMessage": " We have sent a password reset email to {$START_TAG_SPAN}{$INTERPOLATION}{$CLOSE_TAG_SPAN}. Please follow the instructions to complete the password reset process.",
    "ForgotPasswordNotification": " If you forget your password, we will send you an email with a password reset link. Please click on that link and follow the instructions to reset your password.",
    "FromDate":"From Date",
    "General": "General",
    "GeneralConfig": "General Config",
    "GeneralInformation": "General Information",
    "GenerateKey": "Generate Key",
    "GlobalScreen": "Global Screen",
    "GlobalScreenCheckConfigurations": "Global Screen Check Configurations",
    "GlobalScreenConfigurations": "Global Screen Configurations",
    "GlobalScreenConfigurationsDetail": "Global Screen Configurations Detail",
    "GlobalScreenConfigurationsSearch": "Global Screen Configurations Search",
    "GlobalScreenLogDetail": "Global Screen Log Detail",
    "GooglePay": "GooglePay",
    "GooglePayNumberTokens24h": "Google Pay - Number of Tokens in 24h",
    "IncorrectCode": "Incorrect Code",
    "IncorrectCode[15]": "Incorrect Code [15]",
    "IncorrectCode[15]/LeftBlankCode[16]": "Incorrect code [15]/Left Blank code [16]",
    "Infomation": "Infomation",
    "InfoMethod": "Info/ Method",
    "InternalServerError": "Internal Server Error",
    "InvalidNewPassword": "The new password must not be the same as the last 5 passwords, please check again.",
    "InvalidOldPassword": "The old password is invalid",
    "InvalidPhoneNumber": "Invalid phone number",
    "InvalidURL": "Invalid URL",
    "IPAddress": "IP Address",
    "IsReady": "Is Ready",
    "ItemName": "Item Name",
    "LabelNeedHelp": "Label Need help",
    "LabelNeedHelp[8]": "Label Need help [8]",
    "LabelSubmit": "Label Submit",
    "LabelSubmit[14]": "Label Submit [14]",
    "LabelSubmit[6]": "Label Submit [6]",
    "LabelTermOfUse": "Label Term of use",
    "LabelTermOfUse[9]": "Label Term of use [9]",
    "Language": "Language",
    "LanguageType": "Language Type",
    "LastEditPasswordDateTime": "Last password reset time: {$INTERPOLATION}",
    "LastimeModified": "Last time Modified",
    "LastLoginDate:Last Login Date": "Last Login Date",
    "LastLoginDate": "Last Login Date",
    "LastName": "Last Name",
    "LastStatusUpdatedAt": "Last Status Updated At",
    "LatestDateTimeSent": "Latest Date TimeSent",
    "LatestEmailReceived": "Latest Email Received",
    "LatestUserSent": "Latest User Sent",
    "LeftBlankCode": "Left Blank Code",
    "LeftBlankCode[16]": "Left Blank Code [16]",
    "ListOfReports": "List of Reports",
    "ListRule": "LIST RULES",
    "ListRuleAddEdit": "LIST RULES (Add and Edit Rules)",
    "Locked": "Locked",
    "LogAction": "Log Action",
    "LogActionHistory": "Log Action History",
    "LogDetail": "Log Detail",
    "LogDetailChanges": "LOG DETAIL (Changes)",
    "LogDetailInformation": "Log Detail Information",
    "LogInformation": "Log Information",
    "MasterCardTokenDetail": "MasterCard Token Detail",
    "MaxVerifyOTP": "MaxVerifyOTP",
    "MCToken": "MC Token",
    "Message.AccessDenied": "You do not have access to view this page within the site.",
    "Message.ActivateAccountConfirm": "Are you sure you want to activate your account??",
    "Message.BINNoExists": "Bin no exists",
    "Message.BinRangeDisabled": "There's a BIN Range recently disabled, please try again",
    "Message.BUCodeExist": "BU Code already exists!",
    "Message.BUDeclaredService": "BU has been declared service",
    "Message.BUDisabled": "BU is disabled, please try again",
    "Message.ChangePasswordSuccess": "Your password has been reset successfully.",
    "Message.ChangesYouMadeMayNotBeSaved": "Changes you made may not be saved.",
    "Message.ChanllengeCancelConfirm": "Do you wish to cancel your payment?",
    "Message.CloneFail": "Clone failed. ",
    "Message.CloneSuccess": "Clone successfully",
    "Message.ComebackLater": "Please try later",
    "Message.ConfirmGenerateKey": "Do you want to create a new key?",
    "Message.ConsumerKey": "Consumer Key",
    "Message.ConsumerKeyValidUntil": "The Consumer Key Valid Until date is later than the current date.",
    "Message.ContactAdminstrator": "Please try again or contact the system Administrator.",
    "Message.CreatePolicyDraftSuccess": "Create Policy Draft Success",
    "Message.DataNotFound": "No data found.",
    "Message.DisableAccountConfirm": "Are you sure you want to disable your account??",
    "Message.Don'tCancel": "Don't Cancel",
    "Message.EmailDuplicate": "Email1 and Email2 must not be identical.",
    "Message.EncryptionCertificate Key": "Encryption Certificate Key",
    "Message.EncryptionCertificateValidUntil": "The Encryption Certificate Valid Until date is later than the current date.",
    "Message.Error403": "You don't have permission!",
    "Message.Failed": "Failed",
    "Message.FailSendMail": "Send Mail Failed ",
    "Message.FailureAction": "Update Failed. ",
    "Message.GenerateKey": "Generate Key",
    "Message.GenerateKeySuccess": "Generate key success",
    "Message.InsertFail": "Insert failed",
    "Message.InsertSuccess": "Insert Success",
    "Message.InsertSuccessfully": "Insert Successfully",
    "Message.IntegrationAuthenticationKey": "Integration Authentication Key",
    "Message.InternalServerError": "Internal server error.",
    "Message.Invalid": " {$PH} Invalid : {$PH_1}",
    "Message.InvalidAction": "Invalid Action.",
    "Message.InvalidCardBINLength": "BIN range consists of 6 or 8 characters",
    "Message.InvalidDate": "Invalid Date",
    "Message.InvalidEmail": "Invalid email format",
    "Message.InvalidEmailCC": "Ensure each email is correctly formatted (e.g., example@domain.com)",
    "Message.InvalidExpireDate": "Expire Date greater than or equal the current day",
    "Message.InvalidFormatYYMM": "Format: YYMM",
    "Message.InvalidMax": "Maximum value is {$INTERPOLATION}",
    "Message.InvalidMaxLength": "Max length {$INTERPOLATION} characters",
    "Message.InvalidMin": "Minimum value is {$INTERPOLATION}",
    "Message.InvalidMinLength": "Must contain at least {$INTERPOLATION} characters",
    "Message.InvalidMinMaxBINLength": "Invalid length",
    "Message.InvalidMonth": "Invalid Month",
    "Message.InvalidPhoneNumber": "The input must contain exactly 10 numeric characters, with the first character starting with 0.",
    "Message.InvalidRequest": "Invalid Request",
    "Message.InvalidSession": "Your session has been expired. Please login again.",
    "Message.InvalidToken": "Invalid Token",
    "Message.KeysInformation": "BU Key",
    "Message.LeaveSite": "Leave site?",
    "Message.MaxDate": "The selected date must be less than",
    "Message.MIDNotExist": "MID not exist",
    "Message.MinDate": "The selected date must be greater than",
    "Message.NewPasswordMatchOldPassword": "The new password must not be the same as the old password, please check again.",
    "Message.No": "No",
    "Message.NotFoundText": "Not found text",
    "Message.NoWhitespace": "Can not input blank",
    "Message.OverSizeFileUpload": "The uploaded file exceeds the allowed size. Please check again.",
    "Message.PasswordPolicyNotMatch": "The new password does not meet the required format, please re-enter.",
    "Message.PhoneNumberExist": "Phone number already exists!",
    "Message.PleaseSelectBU": "Please Select BU before Add Rule",
    "Message.PleaseSelectFileEncryptionCertificateFile": "Please select the Encryption Certificate File.",
    "Message.PleaseSelectFileSigningKeyFile": "Please select the Signing Key File.",
    "Message.PublicKeyRequired": "Public key must not be null.",
    "Message.PublishPolicyDraftSuccess": "Publish Policy Draft Success",
    "Message.PublishPolicySuccess": "Publish Policy Success",
    "Message.ReportCriteriaInformation": "Report Criteria",
    "Message.RequestChangePasswordSuccess": "Password reset instructions have been sent to your email.",
    "Message.required": "Field is required",
    "Message.RequiredFail": "Please fill in required fields",
    "Message.RoleInformation": "Role Information",
    "Message.SelectAtLeastOneAuthenticationMethod": "Select at least one authentication method",
    "Message.SigningKey": "Signing Key",
    "Message.SigningKeyValidUntil": "The Signing Key Valid Until date is later than the current date.",
    "Message.SuccessAction": "Action Successfully.",
    "Message.SuccessSendMail": "Send Mail Successfully",
    "Message.TabCardProgram": "Card Program Information",
    "Message.TheBUHasBeenConfiguredWithAllLanguageTypes": "\n          The Business Unit has been configured with all language types, please check again.",
    "Message.TokenExpiration": "Token has been expired",
    "Message.UnderMaintenance": "Maintenance",
    "Message.UpdateFail": "Failed. ",
    "Message.UpdatePasswordSuccess": "Successfully updated the new password",
    "Message.UpdateSuccess": "Update Success",
    "Message.UpdateSuccessfully": "Update Successfully",
    "Message.ValidConfiguration": "Valid configuration",
    "Message.Yes,Cancel": "Yes, Cancel",
    "Message.Yes": "Yes",
    "MessageBody": "Message Body",
    "MessageHeader": "Message Header",
    "Methods": "Methods",
    "ModifyDateTime": "Modify Date Time",
    "Month": "Month",
    "Name": "Name",
    "NeedHelp": "Need help?",
    "NewPassword": "New Password",
    "NewValue": "New Value",
    "Next": "Next",
    "ngb.alert.close": "Close",
    "ngb.carousel.next": "Next",
    "ngb.carousel.previous": "Previous",
    "ngb.carousel.slide-number": " Slide {$INTERPOLATION} of {$INTERPOLATION_1} ",
    "ngb.datepicker.next-month": "Next month",
    "ngb.datepicker.previous-month": "Previous month",
    "ngb.datepicker.select-month": "Select month",
    "ngb.datepicker.select-year": "Select year",
    "ngb.pagination.first-aria": "First",
    "ngb.pagination.first": "««",
    "ngb.pagination.last-aria": "Last",
    "ngb.pagination.last": "»»",
    "ngb.pagination.next-aria": "Next",
    "ngb.pagination.next": "»",
    "ngb.pagination.previous-aria": "Previous",
    "ngb.pagination.previous": "«",
    "ngb.progressbar.value": "{$INTERPOLATION}",
    "ngb.timepicker.decrement-hours": "Decrement hours",
    "ngb.timepicker.decrement-minutes": "Decrement minutes",
    "ngb.timepicker.decrement-seconds": "Decrement seconds",
    "ngb.timepicker.HH": "HH",
    "ngb.timepicker.hours": "Hours",
    "ngb.timepicker.increment-hours": "Increment hours",
    "ngb.timepicker.increment-minutes": "Increment minutes",
    "ngb.timepicker.increment-seconds": "Increment seconds",
    "ngb.timepicker.minutes": "Minutes",
    "ngb.timepicker.MM": "MM",
    "ngb.timepicker.PM": "{$INTERPOLATION}",
    "ngb.timepicker.seconds": "Seconds",
    "ngb.timepicker.SS": "SS",
    "ngb.toast.close-aria": "Close",
    "NotApplicable": "Not Applicable",
    "Notice": "Notice: ",
    "NumberTokens30days": "'Number of Tokens in 30 days",
    "OK": "OK",
    "OldPassword": "Old password",
    "OldValue": "Old Value",
    "OOB": "OOB",
    "OrderNumber": " STT ",
    "OSName": "OSName",
    "Other": "Other",
    "OtherLanguage": "Other Language",
    "OTPEmail": "OTP Email",
    "OTPEMail": "OTP Email",
    "OTPEOTT": "OTP OTT",
    "OTPOOB": "OTP OOB",
    "OTPOTT": "OTP OTT",
    "OTPSMS": "OTP SMS",
    "Outcome": "Outcome",
    "Page": "Page",
    "PANLength": "PAN Length",
    "Password": "Password",
    "PasswordChangeNotice": "The password consists of 8-64 characters, including at least 1 number, 1 letter, 1 special character and must not be the same as the last 5 passwords.",
    "PayByAccount": "Pay By Account",
    "PendingChange": "Pending Change",
    "PERCENT": "PERCENT",
    "PermissionLog": "Permission Log",
    "PhoneNumber": "Phone Number",
    "PleaseSelectCacheType": "Please Select Cache Type",
    "Policy": "Policy",
    "PolicyAdd": "Policy Add",
    "PolicyCurrent": "PolicyCurrent",
    "PolicyDetail": "Policy Details",
    "PolicyEditor": "Policy Detail",
    "PolicyID": "Policy ID",
    "PolicyInformation": "Policy Information",
    "PolicyLogAction": "Policy Log Action",
    "PolicyLogDetail": "Policy Log Detail",
    "PolicyManagement": "Policy Management",
    "PolicyName": "Policy Name",
    "PolicyPublishedAndBINRangeConfiguration": "Policy Published & BIN-Range Configuration",
    "PolicySearch": "Policy Search",
    "PolicyUnsaveSelectedRule": "New Rules have been selected, do you want to exit without save",
    "Previous": "Previous",
    "Production": " Production",
    "Profile": "Profile",
    "PublicKey": "Public Key",
    "PublishAndConfig": "Publish & Config",
    "PublishDateTime": "Publish Date Time",
    "PublishPolicyConfirmination": "Are you sure to change data ?",
    "PublishPolicyDraft": "Publish Policy Draft",
    "PublishPolicyOverrideConfirmation": "Data has been modified before, this action will affect the old data. Are you sure want to continue?",
    "PushProvisioning": "Push Provisioning",
    "PushProvisioningAdd": "Push Provisioning Add",
    "PushProvisioningDashboard": "Push Provisioning Dashboard",
    "PushProvisioningDashBoard": "Push Provisioning DashBoard",
    "PushProvisioningDetail": "Push Provisioning Detail",
    "PushProvisioningInformation": "Push Provisioning Information",
    "PushProvisioningManagement": "Push Provisioning Management",
    "PushProvisioningRegister": "Register Push Provisioning",
    "PushProvisioningSearch": "Push Provisioning Search",
    "PwdRuleChar": "At least 1 alphabetic character",
    "PwdRuleLength8And64": "From 8 to 64 characters",
    "PwdRuleNum": "At least 1 numeric character",
    "PwdRuleSpec": "At least 1 special character. For example: !@#$%^*()”",
    "Quantity": "Quantity",
    "ReasonCode": "ReasonCode",
    "Rejected": "Rejected ",
    "ReloadAll": "Reload all",
    "ReloadCache": "Remove Cache",
    "Remark:Remark": "Remark ",
    "Remark": "Remark",
    "Remove": "Remove",
    "RemoveCache": "Remove Cache",
    "RemovePolicyDraftConfirmination": "Do you want to continue? This will delete the existing draft of the Policy. Any unsaved changes will be lost",
    "Report": "Report",
    "ReportCriteria": "Report Criteria",
    "ReportGroup": "Report Group",
    "RequestChangePassword": "Send request to reset password.",
    "ReturnHomePage": "Return Homepage",
    "ReturnToLoginPage": "Return to login",
    "ReviewRule": "Review Rule",
    "Role": " Role ",
    "RoleName": "Role Name",
    "RowPerPage": "Number of Lines",
    "RReq": "RReq",
    "RRes": "RRes",
    "Rule": "Rule",
    "RuleDescription": "Rule Description",
    "RuleDetail": "Rule Details",
    "RuleID": "Rule ID",
    "RuleLog": "Rule Log",
    "RuleLogAction": "Rule Log Action",
    "RuleManagement": "Rule Management",
    "RuleName": "Rule Name",
    "RuleSearch": "Search Rule",
    "RulesEditor": "Rules Editor",
    "RulesManagement": "Rules Managment",
    "RulesSearch": "Rules Search",
    "RuleStatement": "RuleStatement",
    "Save": "Save",
    "Search": "Search",
    "SendMail": "Send Mail",
    "SendMailConfirmation": " Are you sure to send mail?",
    "SendPushProvisioningReport": "Send Push Provisioning Report",
    "SendRequest": "Send Request",
    "Service": "Service",
    "ServiceInformation": "Service Information",
    "ServiceName": "ServiceName",
    "SetPasswordTitle": "Set Password",
    "SignIn": "Log In",
    "SigningKeyAlias": "Signing Key Alias",
    "SigningKeyFile": "Signing Key File",
    "SigningKeyPassword": "Signing Key Password",
    "SigningKeyValidUntil" : "Signing Key Valid Until",
    "SignInTitle": "LOG IN",
    "SignOut": "Log Out",
    "Start": "Start",
    "Status: Status": "Status",
    "Status:Status": "Status",
    "Status": "Status",
    "StatusPublish": "Status Publish",
    "StepUpMethods": "Step-Up Method(s):",
    "StepUpMethodsConfig": "Step-Up Methods Config",
    "Submit": "Submit",
    "Success": "Success",
    "SuspectedFraud": "Suspected Fraud",
    "System": "System",
    "Tab.AdditionalFields": "Additional Fields",
    "Tab.BillingInfomation": "Billing Infomation",
    "Tab.BusinessUnitInfomation": "Business Unit Infomation",
    "Tab.ErrorMessageInformation": "Error Message Information",
    "Tab.FailWithFeedbackInformation": "Fail With Feedback Information",
    "Tab.LogDetailInfomation": "Log Detail Infomation",
    "Tab.RuleInfomation": "Rule Infomation",
    "Tab.RuleStatememt": "Rule Statememt",
    "Tab.StepUpInformation": "Step-up Information",
    "Tab.SystemInformation": "System Information",
    "Tab.TokenInfomation": "Token details",
    "TemplateEmail": "Template Email",
    "TermOfUse": "Term of use?",
    "TestConfiguration": "Test Configuration",
    "TestMail": "Test Mail",
    "ThreeDSRequestorChallengeInd": "ThreeDSRequestorChallengeInd",
    "TimeBlockPAN": "TimeBlockPAN",
    "TimeConfigFrictionless": "TimeConfigFrictionless",
    "Title": "Title",
    "Title[11]": "Title [11]",
    "Title[17]": "Title [17]",
    "Title[4]": "Title [4]",
    "To_Email": "To Email",
    "ToDate" : "To Date",
    "TokenHistory": "Token History",
    "TokenManagement": "Token Management",
    "TokenManagementDetail": "Token Management Detail",
    "TokenManagementSearch": "Token Management Search",
    "TokenType": "Token Type",
    "Total": "Total",
    "TotalCaches": "Total caches",
    "TransactionDetail[12]": "Transaction Detail [12]",
    "TransactionDetail[18]": "Transaction Detail [18]",
    "TransactionID": "Transaction ID",
    "TransactionResult": "Transaction Result",
    "TransactionStatus": "Transaction Status",
    "TUR": "TUR",
    "UAT": " UAT",
    "Up/Down": " Up/Down ",
    "Update": "Update",
    "UpdateAccountConfirm": "Are you sure you want to update your account information??",
    "Updated User": "Updated User",
    "UpdatedAt": " Date Time Update",
    "UpdatedBy": "User Update",
    "UpdatedUser": "Updated User",
    "URL": "URL",
    "UrlLogo": "Url Logo",
    "URLlogo": "URL logo",
    "URLNeedHelp": "URL Need help",
    "URLTermOfUse": "URL Term of use",
    "User Modify": "User Modify",
    "User": "User",
    "UserAdd": "User Add",
    "UserID": "User ID",
    "UserIDModify": "User ID Modify",
    "UserInformation": "User Information",
    "UserManagement": "User Management",
    "UserModify": "User Modify",
    "UserName": "Username",
    "UsernameUpdate": "User Updated",
    "UserPublish": "User Publish",
    "UserPublished": "UserPublished",
    "UserSearch": "User Search",
    "ValidateLabel": "Validate Label",
    "ValidateLabel[19]": "Validate Label [19]",
    "ValidateLink": "Validate Link",
    "ValidateLink[20]": "Validate Link [20]",
    "ValidationScreenOTPEmail": "Validation Screen OTP Email",
    "ValidationScreenOTPOOB": "Validation Screen OTP OOB",
    "ValidationScreenOTPOTT": "Validation Screen OTP OTT",
    "ValidationScreenOTPSMS": "Validation Screen OTP SMS",
    "Value": "Value",
    "VerificationCode": "Verification Code",
    "VerificationCode[13]": "Verification Code [13]",
    "View": "View",
    "ViewDetailMasterCard": " View Detail Master Card ",
    "ViewPolicyCurrent": "View version Current",
    "ViewPolicyDraft": "View version Draft",
    "VisibleAdminOnly": "Show with administrators only",
    "ACAS-TransactionInquiry": "ACAS - TransactionInquiry",
    "TransactionInquiry": "TransactionInquiry",
    "Last4digit": "Last 4 digits",
    "TransactionFrom": "Transaction From",
    "TransactionTo": "Transaction To",
    "TransactionDateTime": "Transaction Date Time",
    "CardNumberMasked": "Card Number Masked",
    "BUname": "BU Name",
    "AuthenticationType": "Authentication Type",
    "CurrencyCode": "Currency Code",
    "PurchaseAmt": "Purchase Amount",
    "AuthenticationStatus": "Authentication Status",
    "DeviceChannel": "Device Channel",
    "MessageCategory": "Message Category",
    "AccountType": "Account Type",
    "AcquirerBIN": "Acquirer BIN",
    "MerchantCategoryCode": "Merchant Category Code",
    "MerchantName": "Merchant Name",
    "MerchantCountry": "Merchant Country",
    "Unverified": "Unverified",
    "AuthenticationRequired": "Authentication Required",
    "ZZ": "EndOfTranslations"
  }
}
